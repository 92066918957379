import Lazyload from "lazyload"

class LoadMorePosts {
  constructor(postsPageID) {
    this.root_url = localized?.root_url ?? ""
    console.log("root url from module laod more posts", this.root_url)
    this.lastPostObserver
    this.postsLoading
    this.postsPageID = postsPageID ?? 0
    this.newsPageContent = jQuery(`#${this.postsPageID}`).children(".post-item-content")
    this.observeLastPost()
  }

  events() {}

  loadMorePosts() {
    if (!this.postsLoading && this.newsPageContent.length) {
      this.postsLoading = true
      const offset = jQuery(".news-item").length // number of loaded news items
      jQuery.getJSON(`${localized.root_url}/wp-json/wp/v2/posts?per_page=${localized.posts_per_page}&offset=${offset}`, posts => {
        // console.log(posts)
        if (posts.length) {
          jQuery(`#${this.postsPageID}`).children(".post-item-content").append(`
          ${posts
            .map(
              post => `
          <a class="news-item" href="${post.link}" data-id="${post.id}" data-history="${post.slug}" data-title="${post.title.rendered}">
          <div class="news-item__date">${post.date_formated}</div>
          <h2 class="news-item__title">${post.title.rendered}</h2>
          ${
            post.featImage
              ? `
            <div class="news-item__featured-image">
              <img class="lazyload" data-src="${post.featImage.url}" data-srcset="${post.featImage.srcset}" sizes="100vw" alt="${post.featImage.alt}">
            </div>
          `
              : ``
          }
          <div class="news-item__excerpt">${post.excerpt.rendered}</div>
          <div class="news-item__read-more">... read more</div>
        </a>
          `
            )
            .join("")}
        `)
          this.addLazyloadToElement(document.querySelector(`[id="${this.postsPageID}"]`))
          this.observeLastPost()
          this.postsLoading = false
        } else {
          // no more posts
          this.lastPostObserver.disconnect()
          jQuery(`#${this.postsPageID}`).children(".post-item-content").append(`
          <a class="news-item">
            <div class="news-item__excerpt">NO MORE OLDER NEWS
            </div>
          </a>`)
        }
      })
    }
  }

  observeLastPost() {
    if (this.lastPostObserver) {
      this.lastPostObserver.disconnect()
    } else {
      this.lastPostObserver = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // console.log("Last Post ENTER")
            this.loadMorePosts()
          }
        },
        {
          root: null,
          threshold: 0.01 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    const lastPost = document.querySelector(".news-item:last-child")

    lastPost ? this.lastPostObserver.observe(lastPost) : ""
  }

  addLazyloadToElement(elem) {
    // https://github.com/tuupola/lazyload
    // $("img.lazyload").lazyload();
    // lazyload();

    // const elem = document.querySelector(`[id="${elemID}"]`);
    // const elem = $elem[0]
    const images = elem.querySelectorAll("img.lazyload")

    // console.log("lazyload images", images, " in container ", $elem);

    new Lazyload(images, {
      root: null,
      rootMargin: "50px",
      threshold: 0
    })

    // add load event listener for lazyload images
    // $elem.find(`img.lazyload`).on("load", function () {
    //   // console.log("img on elem xx loaded", $elem.attr('id'));
    //   jQuery(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
    // })

    images.forEach(image => {
      image.addEventListener("load", () => {
        image.classList.add("loaded")
      })
    })
  }
}

export default LoadMorePosts
